import React, { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Checkbox, TextField, IconButton, Stack, Box, Paper } from '@mui/material'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { Table, Filters } from 'components/Table'
import { Select } from 'components'
import Changes from './Changes'
import TableActions from './TableActions'
import RowActions from './RowActions'
import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'

import {
  CompanyNameCell,
  CountryCell,
  CreditMaxCell,
  PercentageCell,
  AmountCell,
  EmployeeIntervalCell,
  ScoreCell,
  DefaultHeaderCell,
  TextCell,
} from './cells'

import {
  formatData,
  getCompanyName,
  getAddress,
  getLocalId,
  getCurrency,
  getPolicyValues,
  shouldHideScore,
} from '../../controller'
import { DoubleScrollbar } from 'components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { classes } from '../../styles/monitor-table'
import TableName from './TableName'
import EditSelectedList from './EditSelectedList'
import EditRowMenu from './EditRowMenu'
const defaultTableFields = [
  'companyName',
  'localId',
  'country',
  'score',
  'creditMax',
  'profitLoss',
  'equity',
  'employeeInterval',
]
const MonitorTable = ({ entries, name }) => {
  const dispatch = useDispatch()
  const reactIntl = useIntl()
  const monitorTableFields = useSelector(
    (state) =>
      state.auth?.settings?.monitorTableFields?.toString() ??
      defaultTableFields.toString()
  )

  const { lists, selectedList } = useSelector((state) => state.risika.newRiskMonitoring)

  const [isEditing, setIsEditing] = React.useState(false)

  const [selectedRows, setSelectedRows] = React.useState([])

  const [page, setPage] = React.useState(0)
  /***********
   * Filters *
   ***********/

  const scoreSort = useMemo(
    () => (rowA, rowB, columnId) => {
      const standardScoreOrder = [
        '-',
        'High',
        '1',
        '2',
        '3',
        'Medium',
        '4',
        '5',
        '6',
        'Low',
        '7',
        '8',
        '9',
        '10',
      ]

      const scoreA =
        rowA.original.score?.toString() ?? rowA.original.riskAssessment ?? '-'
      const scoreB =
        rowB.original.score?.toString() ?? rowB.original.riskAssessment ?? '-'

      const indexScoreA = standardScoreOrder.indexOf(scoreA)
      const indexScoreB = standardScoreOrder.indexOf(scoreB)

      return indexScoreA > indexScoreB ? -1 : 1
    },
    []
  )

  /***********
   * Filters *
   ***********/

  const companySearchFilter = React.useCallback(
    (inputProps) => (
      <TextField
        variant="standard"
        sx={{ width: 300 }}
        {...inputProps}
        placeholder={reactIntl.messages[intl.riskMonitoring('company-search')]}
      />
    ),
    [reactIntl.messages]
  )

  const localIdFilter = React.useCallback(
    (inputProps) => (
      <TextField
        variant="standard"
        style={{ width: 150 }}
        {...inputProps}
        placeholder={reactIntl.messages[intl.riskMonitoring('local-id-search')]} // Fix this
      />
    ),
    [reactIntl.messages]
  )

  const internalIdFilter = React.useCallback(
    (inputProps) => (
      <TextField
        variant="standard"
        style={{ width: 160 }}
        {...inputProps}
        placeholder={reactIntl.messages[intl.riskMonitoring('internal-id-search')]}
      />
    ),
    [reactIntl.messages]
  )

  const scoreFilter = React.useCallback(
    (selectProps) => {
      return (
        <Select
          {...selectProps}
          values={selectProps.values.map((value) => ({
            value,
            label: reactIntl.messages[intl.riskMonitoringOverview(value)],
          }))}
        />
      )
    },
    [reactIntl.messages]
  )
  const countryFilter = React.useCallback(
    (selectProps) => {
      return (
        <Select
          {...selectProps}
          values={selectProps.values.map((value) => ({
            value,
            label: reactIntl.messages[intl.riskMonitoringOverview(value)],
          }))}
        />
      )
    },
    [reactIntl.messages]
  )

  const statusSelectFilter = React.useCallback(
    (selectProps) => {
      const defaultValue = {
        value: '',
        label: reactIntl.messages[intl.generic('all')],
      }

      return (
        <Select
          {...selectProps}
          values={[
            defaultValue,
            ...selectProps.values.map((value) => ({ value, label: value })),
          ]}
        />
      )
    },
    [reactIntl.messages]
  )

  /*****************
   * Sub Component *
   *****************/

  const renderRowSubComponent = ({ row }) => <Changes row={row} />

  const handleCellRendering = React.useCallback(
    (cellName) => {
      switch (cellName) {
        case 'companyName':
          return {
            Header: (
              <DefaultHeaderCell
                text={<FormattedMessage id={intl.riskMonitoring('company')} />}
              />
            ),
            Cell: ({ row }) => {
              return (
                <CompanyNameCell
                  name={getCompanyName(row)}
                  address={getAddress(row)}
                  localId={getLocalId(row)}
                  creditPolicy={getPolicyValues(row)}
                />
              )
            },
            accessor: 'companyName',
            Filter: ({ column }) => {
              return (
                <Filters.Search InputComponent={companySearchFilter} column={column} />
              )
            },
            width: 400,
          }
        case 'localId':
          return {
            Header: (
              <DefaultHeaderCell
                text={<FormattedMessage id={intl.riskMonitoring('local-id')} />}
              />
            ),
            Cell: ({ cell }) => (
              <TextCell
                sx={{ textAlign: 'left' }}
                cell={{ ...cell, value: cell.value.id }}
              />
            ),
            accessor: 'localId',
            Filter: ({ column }) => (
              <Filters.Search InputComponent={localIdFilter} column={column} />
            ),
            filter: 'localId',
            width: 200,
          }
        case 'score':
          return {
            Header: (
              <DefaultHeaderCell
                text={<FormattedMessage id={intl.riskMonitoring('risika-score')} />}
              />
            ),
            Cell: ({ cell, row }) => {
              return (
                <ScoreCell
                  score={row?.original?.score}
                  riskAssessment={row?.original?.riskAssessment}
                  localId={getLocalId(row)}
                  cell={cell}
                  hideScore={shouldHideScore(row)}
                />
              )
            },
            accessor: 'riskAssessmentCode',
            Filter: ({ column }) => (
              <Filters.Score InputComponent={scoreFilter} column={column} />
            ),
            filter: 'groupSelect',
            width: 166,
            sortType: scoreSort,
          }
        case 'country':
          return {
            Header: (
              <DefaultHeaderCell
                text={<FormattedMessage id={intl.riskMonitoring('country')} />}
              />
            ),
            Cell: ({ row }) => (
              <LazyLoadComponent>
                <CountryCell localId={getLocalId(row)} />{' '}
              </LazyLoadComponent>
            ),
            accessor: 'country',
            Filter: ({ column }) => {
              return <Filters.Country InputComponent={countryFilter} column={column} />
            },
            filter: 'groupSelect',
            width: 1,
          }

        // case 'localID'
        case 'status':
          return {
            Header: (
              <DefaultHeaderCell
                text={<FormattedMessage id={intl.riskMonitoring('status')} />}
              />
            ),
            Cell: TextCell,
            accessor: 'status',
            Filter: ({ column }) => (
              <Filters.Select InputComponent={statusSelectFilter} column={column} />
            ),
            width: 256,
          }
        // case 'address'
        // case 'changes'
        // case 'statusCode'
        case 'creditMax':
          return {
            Header: (
              <DefaultHeaderCell
                text={<FormattedMessage id={intl.riskMonitoring('credit-max')} />}
                sx={{ textAlign: 'center' }}
              />
            ),
            Cell: ({ cell, row }) => (
              <CreditMaxCell
                cell={cell}
                localId={getLocalId(row)}
                currency={getCurrency(row)}
              />
            ),
            accessor: 'creditMax',
            Filter: ({ column }) => (
              <div style={{ visibility: 'hidden' }}>
                <Filters.Select InputComponent={statusSelectFilter} column={column} />{' '}
              </div>
            ),
            width: 166,
            sortType: 'basic',
          }
        // case 'companyType'
        case 'profitLoss':
          return {
            Header: (
              <DefaultHeaderCell
                data-cy="profit-loss-header"
                sx={classes.centerCell}
                text={<FormattedMessage id={intl.riskMonitoring('profit-loss')} />}
              />
            ),
            Cell: ({ cell, row }) => (
              <AmountCell
                cell={cell}
                currency={getCurrency(row)}
                dataCy="profit-loss-cell"
              />
            ),
            accessor: 'profitLoss',
            Filter: ({ column }) => (
              <div style={{ visibility: 'hidden' }}>
                <Filters.Select InputComponent={statusSelectFilter} column={column} />{' '}
              </div>
            ),
            width: 256,
            sortType: 'basic',
          }
        // case 'solidity'
        // case 'liquidity'
        // case 'industryCode'
        // case 'dateOfIncorporation'
        case 'equity':
          return {
            Header: (
              <DefaultHeaderCell
                sx={classes.centerCell}
                text={<FormattedMessage id={intl.riskMonitoring('equity')} />}
              />
            ),
            Cell: ({ cell, row }) => (
              <AmountCell cell={cell} currency={getCurrency(row)} />
            ),
            accessor: 'equity',
            Filter: ({ column }) => (
              <div style={{ visibility: 'hidden' }}>
                <Filters.Select InputComponent={statusSelectFilter} column={column} />
              </div>
            ),
            width: 256,
            sortType: 'basic',
          }
        case 'employeeInterval':
          return {
            Header: (
              <DefaultHeaderCell
                sx={{ ...classes.centerCell, textAlign: 'center' }}
                text={
                  <FormattedMessage
                    style={{ textAlign: 'center' }}
                    id={intl.riskMonitoring('employee-interval')}
                  />
                }
              />
            ),
            Cell: EmployeeIntervalCell,
            accessor: 'employeeInterval',
            Filter: ({ column }) => (
              <div style={{ visibility: 'hidden' }}>
                <Filters.Select InputComponent={statusSelectFilter} column={column} />{' '}
              </div>
            ),
            width: 216,
          }
        case 'currency':
          return {
            Header: (
              <DefaultHeaderCell
                sx={classes.centerCell}
                text={<FormattedMessage id={intl.riskMonitoring('currency')} />}
              />
            ),
            Cell: TextCell,
            accessor: 'currency',
            Filter: ({ column }) => (
              <div style={{ visibility: 'hidden' }}>
                <Filters.Select InputComponent={statusSelectFilter} column={column} />{' '}
              </div>
            ),
            width: 156,
          }
        case 'companyType':
          return {
            Header: (
              <DefaultHeaderCell
                sx={classes.centerCell}
                text={<FormattedMessage id={intl.riskMonitoring('company-type')} />}
              />
            ),
            Cell: TextCell,
            accessor: 'companyType',
            Filter: ({ column }) => (
              <div style={{ visibility: 'hidden' }}>
                <Filters.Select InputComponent={statusSelectFilter} column={column} />{' '}
              </div>
            ),
            width: 156,
          }
        case 'solidity':
          return {
            Header: (
              <DefaultHeaderCell
                sx={classes.centerCell}
                text={<FormattedMessage id={intl.riskMonitoring('solidity')} />}
              />
            ),
            Cell: PercentageCell,
            accessor: 'solidity',
            Filter: ({ column }) => (
              <div style={{ visibility: 'hidden' }}>
                <Filters.Select InputComponent={statusSelectFilter} column={column} />{' '}
              </div>
            ),
            width: 156,
            sortType: 'basic',
          }
        case 'liquidity':
          return {
            Header: (
              <DefaultHeaderCell
                sx={classes.centerCell}
                text={<FormattedMessage id={intl.riskMonitoring('liquidity')} />}
              />
            ),
            Cell: PercentageCell,
            accessor: 'liquidity',
            Filter: ({ column }) => (
              <div style={{ visibility: 'hidden' }}>
                <Filters.Select InputComponent={statusSelectFilter} column={column} />{' '}
              </div>
            ),
            width: 156,
            sortType: 'basic',
          }
        case 'dateOfIncorporation':
          return {
            Header: (
              <DefaultHeaderCell
                sx={classes.centerCell}
                text={
                  <FormattedMessage id={intl.riskMonitoring('date-of-incorporation')} />
                }
              />
            ),
            Cell: TextCell,
            accessor: 'dateOfIncorporation',
            Filter: ({ column }) => (
              <div style={{ visibility: 'hidden' }}>
                <Filters.Select InputComponent={statusSelectFilter} column={column} />
              </div>
            ),
            width: 156,
          }
        case 'internalId':
          return {
            Header: (
              <DefaultHeaderCell
                sx={classes.centerCell}
                text={<FormattedMessage id={intl.riskMonitoring('internalId')} />}
              />
            ),
            Cell: TextCell,
            Filter: ({ column }) => (
              <Filters.Search InputComponent={internalIdFilter} column={column} />
            ),
            accessor: 'internalId',
            width: 156,
          }
        // There is no backend data for now (DATA-1017)
        case 'capital':
          return {
            Header: (
              <DefaultHeaderCell
                sx={classes.centerCell}
                text={<FormattedMessage id={intl.riskMonitoring('capital')} />}
              />
            ),
            Cell: TextCell,
            Filter: ({ column }) => (
              <div style={{ visibility: 'hidden' }}>
                <Filters.Select InputComponent={statusSelectFilter} column={column} />{' '}
              </div>
            ),
            accessor: 'capital',
            width: 156,
          }
        default:
          return null
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      classes.centerCell,
      companySearchFilter,
      countryFilter,
      scoreFilter,
      statusSelectFilter,
    ]
  )

  const columns = React.useMemo(
    () => [
      {
        id: 'expander',
        reducedPadding: true,
        Header: ({ rows, isAllRowsExpanded, getToggleAllRowsExpandedProps }) => (
          <Box>
            {/* <IconButton {...getToggleAllRowsExpandedProps()}> */}
            <IconButton {...getToggleAllRowsExpandedProps()} sx={classes.changesButton}>
              {isAllRowsExpanded ? (
                <FontAwesomeIcon icon={faChevronUp} />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} />
              )}
            </IconButton>
          </Box>
        ),
        Cell: ({ row }) =>
          row.original.changes?.length ? (
            <IconButton {...row.getToggleRowExpandedProps()} sx={classes.changesButton}>
              {row.isExpanded ? (
                <FontAwesomeIcon icon={faChevronUp} />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} />
              )}
            </IconButton>
          ) : null,
      },
      {
        id: 'selection',
        reducedPadding: true,
        width: 10,
        Header: ({
          getToggleAllRowsSelectedProps,
          selectedFlatRows,
          rows,
          isAllRowsExpanded,
          getToggleAllRowsExpandedProps,
        }) => (
          <div style={{ display: 'flex' }}>
            <Checkbox
              data-cy="monitor-select-all-toggle"
              {...getToggleAllRowsSelectedProps()}
              indeterminate={
                selectedFlatRows.length > 0 && selectedFlatRows.length < rows.length
              }
            />
          </div>
        ),
        Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
      },
      ...monitorTableFields
        .split(',')
        ?.map((x) => {
          return handleCellRendering(x)
        })
        .filter((cell) => !!cell),
      {
        id: 'actions',
        width: 0,
        Header: () => null,
        Cell: ({ row }) => <EditRowMenu row={row} />,
      },
    ],
    // Console says 1 thing and eslint another so just ignore this one !!!
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [classes.changesButton, classes.rowActions, handleCellRendering, monitorTableFields]
  )
  const data = React.useMemo(() => formatData(entries), [entries])

  return (
    <Paper sx={classes.paper}>
      <Box sx={classes.tableTopMenu}>
        {selectedRows.length > 0 ? (
          <RowActions rows={selectedRows} />
        ) : (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={classes.listName}
          >
            <Box>
              {isEditing ? (
                <EditSelectedList
                  dispatch={dispatch}
                  selectedList={selectedList}
                  setIsEditing={setIsEditing}
                  name={name}
                />
              ) : (
                <TableName setIsEditing={setIsEditing} listName={name} />
              )}
            </Box>
            <TableActions />
          </Stack>
        )}
      </Box>
      <DoubleScrollbar>
        <Box sx={classes.monitoringTable}>
          <Table
            loading={lists?.[selectedList]?.listLoading}
            columns={columns}
            data={data}
            renderRowSubComponent={renderRowSubComponent}
            setSelectedRows={setSelectedRows}
            pageIndex={page}
            setPage={setPage}
            dataCy="non-ecp-monitoring-table"
          />
        </Box>
      </DoubleScrollbar>
    </Paper>
  )
}

export default MonitorTable
